import { graphql } from 'gatsby'
import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import TailwindLayout from '../../components/Layouts/TailwindLayout'
import { SupportMain } from '@truphone/support-components'
import ContactSupportIcon from '../../images/icons/contact.svg'
import { Helmet } from 'react-helmet'

function SupportIndex({
  data: { datoCmsSupportIndex, datoCmsSite },
  pageContext: { menu, footer, cookiePrompt, consumer, noIndex }
}) {
  return (
    <>
      <HelmetDatoCms
        seo={datoCmsSupportIndex.seoMetaTags || datoCmsSite.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <Helmet>{noIndex && <meta name="robots" content="noindex,nofollow" />}</Helmet>
      <TailwindLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt} consumer={consumer}>
        <SupportMain
          dark={!consumer}
          title={datoCmsSupportIndex.title}
          ctas={datoCmsSupportIndex.headerLink}
          blocks={datoCmsSupportIndex.supportProducts
            .filter((x) => !!x.slug)
            .map((product) => ({
              icon: product.icon.url,
              title: product.title,
              href: `${datoCmsSupportIndex.url}${product.slug}/`
            }))}
          banner={{
            icon: ContactSupportIcon,
            title: datoCmsSupportIndex.contactSupportTitle,
            ctas: datoCmsSupportIndex.contactSupportCtas
          }}
        />
      </TailwindLayout>
    </>
  )
}

export default SupportIndex

export const query = graphql`
  query ($locale: String!, $id: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsSupportIndex(locale: { eq: $locale }, originalId: { eq: $id }) {
      seoMetaTags {
        tags
      }
      url
      title
      headerLink {
        text
        title
        href
        openTab
      }
      supportProducts {
        title
        slug
        icon {
          url
        }
      }
      contactSupportTitle
      contactSupportCtas {
        title
        text
        href
      }
    }
  }
`
